/*
 GENERATED - DO NOT EDIT
 This file is generated from the templates/Destinations.ts.template file.
 Original file: https://github.com/rudderlabs/rudder-integrations-config/blob/develop/generated/Destinations.ts
 
 NOTE: Contains only constants for device mode destinations
 */
export const ACTIVE_CAMPAIGN_NAME = 'ACTIVE_CAMPAIGN';
export const ACTIVE_CAMPAIGN_DISPLAY_NAME = 'ActiveCampaign';
export const ADOBE_ANALYTICS_NAME = 'ADOBE_ANALYTICS';
export const ADOBE_ANALYTICS_DISPLAY_NAME = 'Adobe Analytics';
export const ADROLL_NAME = 'ADROLL';
export const ADROLL_DISPLAY_NAME = 'Adroll';
export const AM_NAME = 'AM';
export const AM_DISPLAY_NAME = 'Amplitude';
export const APPCUES_NAME = 'APPCUES';
export const APPCUES_DISPLAY_NAME = 'Appcues';
export const AXEPTIO_NAME = 'AXEPTIO';
export const AXEPTIO_DISPLAY_NAME = 'Axeptio';
export const BINGADS_NAME = 'BINGADS';
export const BINGADS_DISPLAY_NAME = 'Bing Ads';
export const BRAZE_NAME = 'BRAZE';
export const BRAZE_DISPLAY_NAME = 'Braze';
export const BUGSNAG_NAME = 'BUGSNAG';
export const BUGSNAG_DISPLAY_NAME = 'Bugsnag';
export const CHARTBEAT_NAME = 'CHARTBEAT';
export const CHARTBEAT_DISPLAY_NAME = 'Chartbeat';
export const CLEVERTAP_NAME = 'CLEVERTAP';
export const CLEVERTAP_DISPLAY_NAME = 'CleverTap';
export const COMMANDBAR_NAME = 'COMMANDBAR';
export const COMMANDBAR_DISPLAY_NAME = 'CommandBar';
export const CONVERTFLOW_NAME = 'CONVERTFLOW';
export const CONVERTFLOW_DISPLAY_NAME = 'Convertflow';
export const CRITEO_NAME = 'CRITEO';
export const CRITEO_DISPLAY_NAME = 'Criteo';
export const CUSTOMERIO_NAME = 'CUSTOMERIO';
export const CUSTOMERIO_DISPLAY_NAME = 'Customer IO';
export const DCM_FLOODLIGHT_NAME = 'DCM_FLOODLIGHT';
export const DCM_FLOODLIGHT_DISPLAY_NAME = 'DCM Floodlight';
export const DRIP_NAME = 'DRIP';
export const DRIP_DISPLAY_NAME = 'Drip';
export const ENGAGE_NAME = 'ENGAGE';
export const ENGAGE_DISPLAY_NAME = 'Engage';
export const FACEBOOK_PIXEL_NAME = 'FACEBOOK_PIXEL';
export const FACEBOOK_PIXEL_DISPLAY_NAME = 'Facebook Pixel';
export const FULLSTORY_NAME = 'FULLSTORY';
export const FULLSTORY_DISPLAY_NAME = 'Fullstory';
export const GA_NAME = 'GA';
export const GA_DISPLAY_NAME = 'Google Analytics';
export const GA360_NAME = 'GA360';
export const GA360_DISPLAY_NAME = 'Google Analytics 360';
export const GA4_NAME = 'GA4';
export const GA4_DISPLAY_NAME = 'Google Analytics 4 (GA4)';
export const GA4_V2_NAME = 'GA4_V2';
export const GA4_V2_DISPLAY_NAME = 'Google Analytics 4 (GA4) V2';
export const GAINSIGHT_PX_NAME = 'GAINSIGHT_PX';
export const GAINSIGHT_PX_DISPLAY_NAME = 'Gainsight PX';
export const GOOGLE_OPTIMIZE_NAME = 'GOOGLE_OPTIMIZE';
export const GOOGLE_OPTIMIZE_DISPLAY_NAME = 'Google Optimize';
export const GOOGLEADS_NAME = 'GOOGLEADS';
export const GOOGLEADS_DISPLAY_NAME = 'Google Ads';
export const GTM_NAME = 'GTM';
export const GTM_DISPLAY_NAME = 'Google Tag Manager';
export const HEAP_NAME = 'HEAP';
export const HEAP_DISPLAY_NAME = 'Heap.io';
export const HOTJAR_NAME = 'HOTJAR';
export const HOTJAR_DISPLAY_NAME = 'Hotjar';
export const HS_NAME = 'HS';
export const HS_DISPLAY_NAME = 'HubSpot';
export const INTERCOM_NAME = 'INTERCOM';
export const INTERCOM_DISPLAY_NAME = 'Intercom';
export const ITERABLE_NAME = 'ITERABLE';
export const ITERABLE_DISPLAY_NAME = 'Iterable';
export const JUNE_NAME = 'JUNE';
export const JUNE_DISPLAY_NAME = 'JUNE';
export const KEEN_NAME = 'KEEN';
export const KEEN_DISPLAY_NAME = 'Keen';
export const KISSMETRICS_NAME = 'KISSMETRICS';
export const KISSMETRICS_DISPLAY_NAME = 'Kiss Metrics';
export const KLAVIYO_NAME = 'KLAVIYO';
export const KLAVIYO_DISPLAY_NAME = 'Klaviyo';
export const LAUNCHDARKLY_NAME = 'LAUNCHDARKLY';
export const LAUNCHDARKLY_DISPLAY_NAME = 'LaunchDarkly';
export const LEMNISK_NAME = 'LEMNISK';
export const LEMNISK_DISPLAY_NAME = 'Lemnisk Marketing Automation';
export const LINKEDIN_INSIGHT_TAG_NAME = 'LINKEDIN_INSIGHT_TAG';
export const LINKEDIN_INSIGHT_TAG_DISPLAY_NAME = 'Linkedin Insight Tag';
export const LIVECHAT_NAME = 'LIVECHAT';
export const LIVECHAT_DISPLAY_NAME = 'livechat';
export const LOTAME_NAME = 'LOTAME';
export const LOTAME_DISPLAY_NAME = 'Lotame';
export const LYTICS_NAME = 'LYTICS';
export const LYTICS_DISPLAY_NAME = 'Lytics';
export const MATOMO_NAME = 'MATOMO';
export const MATOMO_DISPLAY_NAME = 'Matomo';
export const MICROSOFT_CLARITY_NAME = 'MICROSOFT_CLARITY';
export const MICROSOFT_CLARITY_DISPLAY_NAME = 'Microsoft Clarity';
export const MOENGAGE_NAME = 'MOENGAGE';
export const MOENGAGE_DISPLAY_NAME = 'MoEngage';
export const MOUSEFLOW_NAME = 'MOUSEFLOW';
export const MOUSEFLOW_DISPLAY_NAME = 'Mouseflow';
export const MP_NAME = 'MP';
export const MP_DISPLAY_NAME = 'Mixpanel';
export const NINETAILED_NAME = 'NINETAILED';
export const NINETAILED_DISPLAY_NAME = 'Ninetailed';
export const OLARK_NAME = 'OLARK';
export const OLARK_DISPLAY_NAME = 'Olark';
export const OPTIMIZELY_NAME = 'OPTIMIZELY';
export const OPTIMIZELY_DISPLAY_NAME = 'Optimizely Web';
export const PENDO_NAME = 'PENDO';
export const PENDO_DISPLAY_NAME = 'Pendo';
export const PINTEREST_TAG_NAME = 'PINTEREST_TAG';
export const PINTEREST_TAG_DISPLAY_NAME = 'Pinterest Tag';
export const PODSIGHTS_NAME = 'PODSIGHTS';
export const PODSIGHTS_DISPLAY_NAME = 'Podsights';
export const POST_AFFILIATE_PRO_NAME = 'POST_AFFILIATE_PRO';
export const POST_AFFILIATE_PRO_DISPLAY_NAME = 'Post Affiliate Pro';
export const POSTHOG_NAME = 'POSTHOG';
export const POSTHOG_DISPLAY_NAME = 'PostHog';
export const PROFITWELL_NAME = 'PROFITWELL';
export const PROFITWELL_DISPLAY_NAME = 'ProfitWell';
export const QUALAROO_NAME = 'QUALAROO';
export const QUALAROO_DISPLAY_NAME = 'Qualaroo';
export const QUALTRICS_NAME = 'QUALTRICS';
export const QUALTRICS_DISPLAY_NAME = 'Qualtrics';
export const QUANTUMMETRIC_NAME = 'QUANTUMMETRIC';
export const QUANTUMMETRIC_DISPLAY_NAME = 'Quantum Metric';
export const QUORA_PIXEL_NAME = 'QUORA_PIXEL';
export const QUORA_PIXEL_DISPLAY_NAME = 'Quora Pixel';
export const REDDIT_PIXEL_NAME = 'REDDIT_PIXEL';
export const REDDIT_PIXEL_DISPLAY_NAME = 'Reddit Pixel';
export const REFINER_NAME = 'REFINER';
export const REFINER_DISPLAY_NAME = 'Refiner';
export const ROCKERBOX_NAME = 'ROCKERBOX';
export const ROCKERBOX_DISPLAY_NAME = 'Rockerbox';
export const ROLLBAR_NAME = 'ROLLBAR';
export const ROLLBAR_DISPLAY_NAME = 'rollbar';
export const SATISMETER_NAME = 'SATISMETER';
export const SATISMETER_DISPLAY_NAME = 'SatisMeter';
export const SENDINBLUE_NAME = 'SENDINBLUE';
export const SENDINBLUE_DISPLAY_NAME = 'Sendinblue';
export const SENTRY_NAME = 'SENTRY';
export const SENTRY_DISPLAY_NAME = 'Sentry';
export const SHYNET_NAME = 'SHYNET';
export const SHYNET_DISPLAY_NAME = 'Shynet';
export const SNAP_PIXEL_NAME = 'SNAP_PIXEL';
export const SNAP_PIXEL_DISPLAY_NAME = 'Snap Pixel';
export const SNAPENGAGE_NAME = 'SNAPENGAGE';
export const SNAPENGAGE_DISPLAY_NAME = 'SnapEngage';
export const SPOTIFYPIXEL_NAME = 'SPOTIFYPIXEL';
export const SPOTIFYPIXEL_DISPLAY_NAME = 'Spotify Pixel';
export const SPRIG_NAME = 'SPRIG';
export const SPRIG_DISPLAY_NAME = 'Sprig';
export const TIKTOK_ADS_NAME = 'TIKTOK_ADS';
export const TIKTOK_ADS_DISPLAY_NAME = 'TikTok Ads';
export const TVSQUARED_NAME = 'TVSQUARED';
export const TVSQUARED_DISPLAY_NAME = 'TVSquared';
export const VERO_NAME = 'VERO';
export const VERO_DISPLAY_NAME = 'Vero';
export const VWO_NAME = 'VWO';
export const VWO_DISPLAY_NAME = 'VWO';
export const WOOPRA_NAME = 'WOOPRA';
export const WOOPRA_DISPLAY_NAME = 'WOOPRA';
export const XPIXEL_NAME = 'XPIXEL';
export const XPIXEL_DISPLAY_NAME = 'XPixel';
export const YANDEX_METRICA_NAME = 'YANDEX_METRICA';
export const YANDEX_METRICA_DISPLAY_NAME = 'Yandex.Metrica';
